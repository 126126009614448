import * as RD from '@devexperts/remote-data-ts';
import * as A from 'fp-ts/Array';

import { response } from '@api/helpers';
import { privateAPIv2 } from '~/api/base';

import { PendingItemDTO, PendingItemsData } from '../../pendging';
import { mapPendingTransferDtoToDomain } from '../../helpers';
import { mapApiError } from '@models/ApiError';

export const getPendingTransfers = (portfolioId: string): Promise<PendingItemsData> => {
  return response(privateAPIv2.get<PendingItemDTO[]>(`/transfers/pending/?portfolio_id=${portfolioId}`))
    .then(A.map(mapPendingTransferDtoToDomain))
    .then(RD.success)
    .catch((e) => RD.failure(mapApiError(e)));
};

import { EditACHValuePlain } from '../api/types';
import { BankAccount } from '../types';

export const checkIfEditAccount = (targetBankAccount: BankAccount) => (editValue: EditACHValuePlain) => {
  switch (editValue.type) {
    case 'q2': {
      return editValue.account.endsWith(targetBankAccount.lastDigits);
    }
    case 'manual': {
      return editValue.account.endsWith(targetBankAccount.lastDigits);
    }

    case 'plaid': {
      return editValue.account.endsWith(targetBankAccount.lastDigits);
    }
  }
};

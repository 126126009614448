import * as RD from '@devexperts/remote-data-ts';

import { IsoDate } from '@models/common';
import { ApiError } from '@models/ApiError';

import { ConnectExternalProps } from '~/features/ACH/ConnectExternal';

export type ConnectComponentProps = Pick<ConnectExternalProps, 'onError' | 'showConfirmation' | 'viewType'>;

export type CommonProps = {
  portfolioId: string;
  lockReason?: string;
  removable?: boolean;
  disableExternalAccounts?: boolean;
  onExternalError: ConnectExternalProps['onError'];
  onExternalExit: () => void;
  onManualChanged?: (isManual: boolean) => void;
};

type BankAccountTypeDTO = 'checking' | 'savings';
export type BankAccountDTO = {
  id: string;
  display_name: string;
  type: BankAccountTypeDTO;
  last_digits: string;
  portfolio_id: string;
  created_at?: IsoDate | null;
  balance?: string | number | null;
  connection_type: 'manual' | 'platform' | 'external';
};

export enum BankAccountType {
  CHECKING = 'Checking',
  SAVINGS = 'Savings',
}

export type BankAccountConnectionType = 'platform' | 'manual' | 'plaid' | 'unknown';

export type BankAccount = {
  id: string;
  displayName: string;
  type: BankAccountType;
  lastDigits: string;
  portfolioId: string;
  createdAt?: Date;
  balance?: number;
  connectionType: BankAccountConnectionType;
};

export type BankAccountData = RD.RemoteData<ApiError, BankAccount>;

export type ConnectedBankAccountsData = RD.RemoteData<ApiError, BankAccount[]>;

export enum ACHStateType {
  PLAIN,
  SELECT,
  LIST,
  LOCKED,
  MANUAL,
}

export * from './Plaid.types';

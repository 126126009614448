import * as RD from '@devexperts/remote-data-ts';

import { response } from '@api/helpers';
import { privateAPIv2 } from '~/api/base';
import { constVoid } from 'fp-ts/function';
import { ApiError, mapApiError } from '@models/ApiError';

type Survey = {
  reasonExplanation?: string;
  reason?: string;
};

export type CreateTransferParams = {
  portfolioId: string;
  amount: number;
} & (
  | {
      type: 'deposit';
    }
  | {
      type: 'withdraw';
      survey?: Survey;
      isSellAll: boolean;
    }
);

export const mapWithdrawalSurveyToDTO = (survey?: Survey) => {
  const dto: { reason: string | null; reason_explanation: string | null } = {
    reason: null,
    reason_explanation: null,
  };

  if (survey?.reason) {
    dto.reason = survey.reason;
  }

  if (survey?.reasonExplanation) {
    dto.reason_explanation = survey.reasonExplanation;
  }

  return dto;
};

export const createTransfer = (
  params: CreateTransferParams,
  onSuccess = constVoid,
  onError: (e: ApiError) => void = constVoid,
) => {
  const survey = mapWithdrawalSurveyToDTO(params.type === 'deposit' ? undefined : params.survey);

  const data = {
    portfolio_id: params.portfolioId,
    amount: params.amount,
    direction: params.type === 'deposit' ? 'incoming' : 'outgoing',
    transfer_type: 'ach',
    sell_all: params.type === 'withdraw' ? params.isSellAll : false,
    ...survey,
  };
  return response(privateAPIv2.post('/transfers/', data))
    .then((data) => {
      onSuccess();
      return RD.success(data);
    })
    .catch((e) => {
      const error = mapApiError(e);
      onError(error);
      return RD.failure(error);
    });
};

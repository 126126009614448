import * as RD from '@devexperts/remote-data-ts';
import { ApiError } from '@models/ApiError';
import { IsoDate } from '@models/common';

export type PendingItemDTO = {
  id: string;
  portfolio_id: string;
  amount: number;
  direction: 'incoming' | 'outgoing' | 'INCOMING' | 'OUTGOING';
  created_at: IsoDate;
  bank_account_id: boolean;
  sell_all: boolean;
};

export enum PendingDirection {
  DEPOSIT = 'deposit',
  WITHDRAW = 'withdraw',
}

export type PendingItem = {
  id: string;
  amount: number;
  direction: PendingDirection;
  createdAt: Date;
  isSellAll: boolean;
  portfolioId: string;
};

export type PendingItemsData = RD.RemoteData<ApiError, PendingItem[]>;

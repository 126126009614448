import * as RD from '@devexperts/remote-data-ts';
import { call, put, takeLatest } from 'redux-saga/effects';
import { Action } from 'redux';

import * as builder from '@store/builder';

import { getPendingTransfers } from '../api';
import { PendingItemsData } from './pending-transfers.types';

const action = builder.getModuleAction('PENDING_TRANSFERS');

const SET = action('SET');

const FETCH = action('FETCH');

const initialState: PendingItemsData = RD.initial;

const setAction = (payload: PendingItemsData) => ({
  type: SET,
  payload,
});

type PendingAction = ReturnType<typeof setAction>;

export const PendingReducer = (state = initialState, action: PendingAction) => {
  switch (action.type) {
    case SET: {
      return action.payload;
    }
    default:
      return state;
  }
};

export const loadPendingTransfersInfoAction = (portfolioId: string) => ({ type: FETCH, payload: { portfolioId } });

function* loadSaga(action: Action<typeof FETCH> & { payload: { portfolioId: string } }) {
  yield put(setAction(RD.pending));

  const transfers: PendingItemsData = yield call(getPendingTransfers, action.payload.portfolioId);
  yield put(setAction(transfers));
}

export function* watchPendingTransfersSaga() {
  yield takeLatest(FETCH, loadSaga);
}

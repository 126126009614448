import { isEmpty } from 'lodash';

import { ACHRelationship } from '@models/transfers';

import { getFormValidator, ValidationResponse } from '@utils/forms';

const ROUTING_NUMBER_MAX_LEN = 9;

const validateRequired = (val: any): ValidationResponse =>
  [undefined, NaN, null].includes(val) || isEmpty(val) ? 'Required' : '';

const validateLen = (val: string, requestedLen: number, msg: string): ValidationResponse => {
  const error = validateRequired(val);
  if (error) return error;

  if (val.length !== requestedLen) {
    return msg.replace('{digits}', String(requestedLen));
  }

  return '';
};

const getLenValidator =
  (requestedLen: number, msg: string) =>
  (val: string | number | null = ''): ValidationResponse => {
    return validateLen((val || '').toString(), requestedLen, msg);
  };

export const achValidators = {
  account_number: validateRequired,
  account_owner_name: validateRequired,
  routing_number: getLenValidator(ROUTING_NUMBER_MAX_LEN, 'Routing number must be {digits} digits'),
};

export const formValidator = getFormValidator<ACHRelationship>(achValidators);

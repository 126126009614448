import * as RD from '@devexperts/remote-data-ts';
import { ApiError } from '@models/ApiError';

type TopicAsset = {
  id: number;
  name: string;
};

export type AssetDTO = {
  ticker: string;
  name: string;
  type: 'ETF' | 'BOND' | 'STOCK';
  industry: string;
  socialTopics?: TopicAsset[];
  industryTopics?: TopicAsset[];
  environmentalTopics?: TopicAsset[];
  url?: string;
  description?: string;
  city?: string;
  state?: string;
  country?: string;
  last_price?: number;
};

export const ASSET_TYPE = {
  ETF: 'ETF',
  BOND: 'Bond-ETF',
  STOCK: 'Stock',
} as const;

type AssetTypeKey = keyof typeof ASSET_TYPE;
export type AssetType = (typeof ASSET_TYPE)[AssetTypeKey];

export type AssetEntity = {
  id: string;
  ticker: string;
  name: string;
  type: AssetType;
  industry: string;
  socialTopics: TopicAsset[];
  location?: string;
  websiteLink?: string;
  description?: string;
  price: number;
};

export type AssetPerformanceResponseDTO = Array<{ timestamp: string; close: number; high: number; low: number }>;

export type AssetData = RD.RemoteData<ApiError, AssetEntity>;

export type AssetPerformanceResponse = {
  bars: Array<{ timestamp: string; close: number }>;
  chartData: [string, number][];
  maxRangeValue: number;
  minRangeValue: number;
  changeValue: number;
  priceValue: number;
  performance: number;
};

export type AssetPerformanceResponseData = RD.RemoteData<ApiError, AssetPerformanceResponse>;

export { type GetAssetPerformanceParams } from './api';

import * as RD from '@devexperts/remote-data-ts';

import { response } from '@api/helpers';
import { privateAPIv2 } from '~/api/base';
import { mapApiError } from '@models/ApiError';

import { mapBankAccountDto, mapSetAchPayload } from '../helpers';
import { SetBankAccountParams } from './types';
import { BankAccountDTO } from '../types';

const mapRequestParamsToDTO = (portfolioId: string, params: SetBankAccountParams['payload']) => {
  return {
    provider: params.type,
    portfolio_id: portfolioId,
    payload: mapSetAchPayload(params),
  };
};

export const setBankAccount = (portfolioId: string, params: SetBankAccountParams['payload']) => {
  return response(privateAPIv2.post<BankAccountDTO>('/bank-accounts/', mapRequestParamsToDTO(portfolioId, params)))
    .then(mapBankAccountDto)
    .then(RD.success)
    .catch((e) => RD.failure(mapApiError(e)));
};

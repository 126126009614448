import styled, { css } from 'styled-components';

import { Card, CardContent } from '~/ui/kit/organisms/Card';
import { fontSize } from '~/ui/kit/constants/fonts';
import { gaps } from '~/ui/kit/constants/sizes';
import { IconLocation } from '~/ui/kit/atoms/IconLocation';

type IsEtf = {
  $isETF: boolean;
};

export const CardSection = styled(Card).attrs<IsEtf>({ $fs: 's' })<IsEtf>`
  ${fontSize};
  color: var(--eko-third-color);

  ${CardContent} {
    ${({ $isETF }) =>
      $isETF &&
      css`
        display: flex;
        justify-content: center;
      `}
  }
`;

export const Container = styled.div<IsEtf>`
  display: flex;
  flex-direction: column;
  gap: ${gaps.xxs}px;

  ${({ $isETF }) =>
    $isETF &&
    css`
      gap: ${gaps.m}px;
    `}
`;

export const Section = styled.div``;

export const Line = styled.div.attrs<{ $isBold?: boolean }>({ $fs: 's' })<{ $isBold?: boolean }>`
  color: ${({ $isBold }) => ($isBold ? css`var(--eko-title-color)` : css`var(--eko-third-color)`)};
  ${({ $isBold }) =>
    $isBold &&
    css`
      font-weight: bold;
    `};
  ${fontSize};
`;

export const Ticker = styled(Line)`
  color: var(--eko-third-color);
  text-transform: uppercase;
`;

export const Name = styled.div.attrs({ $fs: 'm' })`
  ${fontSize};
  font-weight: bold;
  color: var(--eko-title-color);
`;

export const Location = styled.div`
  display: flex;
  align-items: center;
  gap: ${gaps.xxs}px;
  color: var(--eko-primary-color);
`;

export const LocationIcon = styled(IconLocation)`
  width: 1.25em;
  height: 1.25em;
`;

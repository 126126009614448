import { isoDateDtoMapper } from '@utils/dtoMappers';
import { getLastDigits } from '@utils/getLastDigits.helper';
import { mapNumberDto } from '~/shared/dtoMappers';

import { BankAccount, BankAccountDTO, BankAccountType } from '../types';

export const mapBankAccountTypeDTO = (dto: BankAccountDTO['type']) => {
  switch (dto) {
    case 'checking':
      return BankAccountType.CHECKING;
    case 'savings':
      return BankAccountType.SAVINGS;
  }
};

const mapBankAccountConnectionType = (dto: BankAccountDTO['connection_type']): BankAccount['connectionType'] => {
  switch (dto) {
    case 'manual':
      return 'manual';
    case 'platform':
      return 'platform';
    case 'external':
      return 'plaid';
  }
  return 'unknown';
};

export const mapBankAccountDto = (dto: BankAccountDTO): BankAccount => ({
  id: dto.id,
  displayName: dto.display_name,
  type: mapBankAccountTypeDTO(dto.type),
  lastDigits: getLastDigits(dto.last_digits),
  portfolioId: dto.portfolio_id,
  createdAt: isoDateDtoMapper(dto.created_at),
  balance: dto.balance === null || dto.balance === undefined ? undefined : mapNumberDto(dto.balance),
  connectionType: mapBankAccountConnectionType(dto.connection_type),
});

import { useCallback, useState } from 'react';
import { constVoid } from 'fp-ts/function';
import * as RD from '@devexperts/remote-data-ts';

import { ApiError } from '@models/ApiError';
import { CreateTransferParams, createTransfer } from '../../api';

type ResponseData = RD.RemoteData<ApiError, unknown>;

export const useCreateTransfer = (onSuccess = constVoid, onError: (e: ApiError) => void = constVoid) => {
  const [data, setData] = useState<ResponseData>(RD.initial);

  const create = useCallback(
    (params: CreateTransferParams) => {
      setData(RD.pending);

      createTransfer(params, onSuccess, onError).then(setData);
    },
    [onSuccess, onError],
  );

  const resetData = useCallback(() => {
    setData(RD.initial);
  }, []);

  return [data, create, resetData] as const;
};

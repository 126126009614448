import { call, put, takeLatest } from 'redux-saga/effects';
import { Action } from 'redux';
import { AxiosError } from 'axios';

import { loadApplication } from '@store/application/actions';

import * as actions from './actions';
import { ConfirmationTypes } from './types';

import OnboardingRequest from '@pages/onboarding_alpaca/request';

type Ctx = Record<string, any>;

type ConfirmAction = Action<typeof ConfirmationTypes.REQUEST> & {
  payload: {
    beforeSubmit: (ctx: Ctx) => Promise<void>;
    ctx: Ctx;
    callback: () => Promise<void>;
  };
};

function* confirmAlpacaOnboarding(action: ConfirmAction) {
  const {
    payload: { beforeSubmit, ctx, callback },
  } = action;

  try {
    yield put(actions.confirmationStarted());
    yield call(beforeSubmit, ctx);
    yield call(() => new OnboardingRequest(ctx).send());
    yield put(loadApplication());
    yield put(actions.confirmationSucceeded());
    yield call(callback);
  } catch (e) {
    yield put(actions.confirmationFailed((e as unknown as AxiosError)?.response?.data?.message || 'Error'));
  }
}

export default function* alpacaOnboardingSaga() {
  yield takeLatest(ConfirmationTypes.REQUEST, confirmAlpacaOnboarding);
}

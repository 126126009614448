import { ACHRelationship } from '@models/transfers';
import { getLastDigits } from '@utils/getLastDigits.helper';
import { mapIsoDate, mapNumberDto } from '~/shared/dtoMappers';

import { BankAccount } from '../types';
import { mapAccountType } from '../helpers';

export const mapAchAccountToBankAccount = (ach: ACHRelationship): BankAccount => ({
  id: ach.account_number,
  displayName: ach.display_name || ach.nickname || ach.account_type,
  type: mapAccountType(ach.account_type),
  lastDigits: getLastDigits(ach.account_number),
  portfolioId: '',
  createdAt: mapIsoDate(ach.created_at),
  balance: ach.balance === undefined || ach.balance === null ? undefined : mapNumberDto(ach.balance),
  connectionType: 'platform' as const,
});

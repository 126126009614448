import * as A from 'fp-ts/Array';
import { pipe } from 'fp-ts/function';

import { mapIsoDate, mapNumberDto } from '~/shared/dtoMappers';

import { PortfolioListItem, PortfolioItemDTO, FirstDepositStatus } from '../../portfolio.types';
import { PortfolioArrayDTO, PortfolioListPlain } from '../portfolioList.types';
import { portfolioCreationOrd } from '../../helpers';

export const mapPortfolioTypeArrayDto = (
  dto: PortfolioItemDTO['portfolio_type'][],
): PortfolioListItem['portfolioType'][] => {
  return dto.map(mapPortfolioTypeDto);
};

const mapPortfolioTypeDto = (dto: PortfolioItemDTO['portfolio_type']): PortfolioListItem['portfolioType'] => {
  switch (dto) {
    case 'MIXED':
    case 'SELF_DIRECT':
      return dto;
    case 'GUIDED':
      return 'PRE_MADE';
  }
};

const mapFirstDepositStatus = (dto: string): FirstDepositStatus => {
  switch (dto) {
    case 'not_started':
    case 'NOT_STARTED':
      return FirstDepositStatus.NOT_INITIATED;
    case 'not_received':
    case 'NOT_RECEIVED':
      return FirstDepositStatus.INITIATED;
    case 'received':
    case 'RECEIVED':
      return FirstDepositStatus.RECEIVED;
    case 'processed':
    case 'PROCESSED':
      return FirstDepositStatus.PROCESSED;
  }
  return FirstDepositStatus.NOT_INITIATED;
};

export const mapPortfolioItemDto = (dto: PortfolioItemDTO): PortfolioListItem => {
  return {
    name: dto.name || dto.id,
    id: dto.id,
    cash: mapNumberDto(dto.cash),
    reservedCash: mapNumberDto(dto.reserved_cash),
    equity: mapNumberDto(dto.equity),
    topics: dto.topics,
    portfolioType: mapPortfolioTypeDto(dto.portfolio_type),
    riskLevel: dto.risk_level,
    firstDepositStatus: mapFirstDepositStatus(dto.first_deposit_status),
    prebuilt_id: dto.prebuilt_id || undefined,
    highlightedTicker: dto.highlighted_symbol || undefined,
    infoBullets: dto.info_bullets || [],
    createdAt: mapIsoDate(dto.created_at),
  };
};

export const mapPortfolioArrayDto = (dto: PortfolioArrayDTO): PortfolioListPlain =>
  pipe(dto, A.map(mapPortfolioItemDto), A.sort(portfolioCreationOrd));

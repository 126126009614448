import * as RD from '@devexperts/remote-data-ts';
import * as O from 'fp-ts/Option';

import { ACHRelationship } from '@models/transfers';
import { ApiError } from '@models/ApiError';

import { BankAccountType } from '~/entities/ach';

export type ACHAccountsValue = RD.RemoteData<ApiError, ACHRelationship[]>;

export enum MenuItem {
  Fund = 'Fund',
  Withdraw = 'Withdraw',
}

type ManualACHPayload = {
  type: 'manual';
  account: string;
  routing: string;
  accountType: BankAccountType;
  owner: string;
};

type PlaidACHPayload = {
  type: 'plaid';
  token: string;
  account: string;
  accountType: BankAccountType;
};

type Q2AchPayload = {
  type: 'q2';
  account: string;
  routing: string;
  balance?: number;
  accountType: BankAccountType;
};

export type SetAchPayload = ManualACHPayload | PlaidACHPayload | Q2AchPayload;

export type SetBankAccountParams = {
  portfolioId: string;
  payload: SetAchPayload;
};

export type EditACHValuePlain = SetBankAccountParams['payload'];

export type EditACHValue = O.Option<EditACHValuePlain>;
